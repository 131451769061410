<template>
  <div>
    <div class="row pt-2">
      <SchedJobParam @scheduleChange="handleScheduleChange" />
    </div>
    <a-col class="mt-5" v-if="loadingGenerealParams" :span="16" :offset="11">
      <a-spin size="large" />
    </a-col>
    <div
      class="card mt-5"
      v-if="selectedSchedule && currentData && !loadingGenerealParams"
    >
      <a-form layout="inline" hide-required-mark>
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <a-form-item :label="$t('smartSchedule.startDay')">
                <a-select
                  style="width: 160px"
                  :default-value="currentData.startDay"
                  @change="(val) => (currentData.startDay = val)"
                >
                  <template v-for="(item, index) in weekDays">
                    <a-select-option :value="index" :key="index">
                      {{ $t("days." + item) }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </div>

            <div class="col-4">
              <a-form-item :label="$t('smartSchedule.endDay')">
                <a-select
                  style="width: 160px"
                  :default-value="currentData.endDay"
                  @change="(val) => (currentData.endDay = val)"
                >
                  <template v-for="(item, index) in weekDays">
                    <a-select-option :value="index" :key="index">
                      {{ $t("days." + item) }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-4">
              <a-button
                :disabled="loadingSave"
                :loading="loadingSave"
                @click="handleSave"
                type="primary"
                icon="save"
                style="float: center; width: 100%"
                >{{ $t("all.save") }}
              </a-button>
            </div>
          </div>
          <div v-for="(day, index) in weekDays" :key="index">
            <div
              class="card card-body mt-2"
              v-if="
                currentData[day] &&
                index >= currentData.startDay &&
                index <= currentData.endDay
              "
            >
              <h5 style="color: blue" class="mb-2">
                {{ $t("days." + day) }}
              </h5>
              <div class="row">
                <div class="col-6">
                  <h6>{{ $t("smartSchedule.workingTime") }} :</h6>
                  <div class="row">
                    <div class="col-6">
                      <a-form-item :label="$t('smartSchedule.startAt')">
                        <a-time-picker
                          :allowClear="false"
                          :minuteStep="15"
                          :default-value="
                            moment(currentData[day].startTime, 'HH:mm')
                          "
                          @change="
                            (val) =>
                              (currentData[day].startTime = val.format('HH:mm'))
                          "
                          format="HH:mm"
                        />
                      </a-form-item>
                    </div>
                    <div class="col-6">
                      <a-form-item :label="$t('smartSchedule.endAt')">
                        <a-time-picker
                          :allowClear="false"
                          :minuteStep="15"
                          :default-value="
                            moment(currentData[day].endTime, 'HH:mm')
                          "
                          @change="
                            (val) =>
                              (currentData[day].endTime = val.format('HH:mm'))
                          "
                          format="HH:mm"
                        />
                      </a-form-item>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <h6>{{ $t("smartSchedule.breakingTime") }} :</h6>
                  <div class="row">
                    <div class="col-6">
                      <a-form-item :label="$t('smartSchedule.startAt')">
                        <a-time-picker
                          :allowClear="false"
                          :minuteStep="15"
                          :default-value="
                            moment(currentData[day].breakStartTime, 'HH:mm')
                          "
                          @change="
                            (val) =>
                              (currentData[day].breakStartTime =
                                val.format('HH:mm'))
                          "
                          format="HH:mm"
                        />
                      </a-form-item>
                    </div>
                    <div class="col-6">
                      <a-form-item :label="$t('smartSchedule.endAt')">
                        <a-time-picker
                          :allowClear="false"
                          :minuteStep="15"
                          :default-value="
                            moment(currentData[day].breakEndTime, 'HH:mm')
                          "
                          @change="
                            (val) =>
                              (currentData[day].breakEndTime =
                                val.format('HH:mm'))
                          "
                          format="HH:mm"
                        />
                      </a-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import SchedJobParam from "@/views/parametrage/smartSchedule/components/schedJodParam/index.vue";

export default {
  name: "subjects",
  components: { SchedJobParam },
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "SubjectForm" });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - General",
    });
  },
  data() {
    return {
      addScheduleLoading: false,
      loadingGenerealParams: false,
      loadingSave: false,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      editingKey: "",
      schedParamsList: [],
      weekDays: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ],
      selectedSchedule: undefined,
      defaultData: {
        startDay: 0,
        endDay: 4,
        monday: {
          startTime: "8:00",
          endTime: "17:00",
          breakStartTime: "12:00",
          breakEndTime: "13:00",
        },
        tuesday: {
          startTime: "8:00",
          endTime: "17:00",
          breakStartTime: "12:00",
          breakEndTime: "13:00",
        },
        wednesday: {
          startTime: "8:00",
          endTime: "17:00",
          breakStartTime: "12:00",
          breakEndTime: "13:00",
        },
        thursday: {
          startTime: "8:00",
          endTime: "17:00",
          breakStartTime: "12:00",
          breakEndTime: "13:00",
        },
        friday: {
          startTime: "8:00",
          endTime: "17:00",
          breakStartTime: "12:00",
          breakEndTime: "13:00",
        },
        saturday: {
          startTime: "8:00",
          endTime: "17:00",
          breakStartTime: "12:00",
          breakEndTime: "13:00",
        },
      },
      moment,
      currentData: null,
    };
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleScheduleChange(val) {
      this.selectedSchedule = val;
      this.currentData = null;
      this.loadingGenerealParams = true;

      apiClient
        .post("/smartSchedule/generalSchedParam/filter", {
          query: { schedJobParam: val },
        })
        .then(({ data }) => {
          if (data.length > 0) {
            this.currentData = data[0];
            console.log("loaded data");
          } else {
            data = JSON.parse(JSON.stringify(this.defaultData));
            apiClient
              .put("/smartSchedule/generalSchedParam", {
                data: {
                  schedJobParam: this.selectedSchedule,
                  ...data,
                },
              })
              .then(({ data }) => {
                this.currentData = data;
              })
              .catch((e) => {
                this.$message.error(this.$t("error.ajout"));
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
        })
        .finally(() => {
          this.loadingGenerealParams = false;
        });
    },
    handleSave() {
      this.loadingSave = true;
      if (!this.currentData._id)
        apiClient
          .put("/smartSchedule/generalSchedParam", {
            data: {
              schedJobParam: this.selectedSchedule,
              ...this.currentData,
            },
          })
          .then((res) => {
            this.$message.success(this.$t("smartSchedule.generalParamsSaved"));
          })
          .catch((e) => this.$message.error(this.$t("error.ajout")))
          .finally(() => {
            this.loadingSave = false;
          });
      else
        apiClient
          .patch("/smartSchedule/generalSchedParam/" + this.currentData._id, {
            data: this.currentData,
          })
          .then((res) => {
            this.$message.success(this.$t("smartSchedule.generalParamsSaved"));
          })
          .catch((e) => this.$message.error(this.$t("error.ajout")))
          .finally(() => {
            this.loadingSave = false;
          });
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
>
