<template>
  <div class="col-8">
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :footer="false"
    >
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" :tab="$t('smartSchedule.addParam')">
          <a-form :form="form" @submit="addSchedule">
            <a-form-item :label="$t('smartSchedule.name')">
              <a-input
                :placeholder="$t('smartSchedule.name')"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.nom'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item :label="$t('smartSchedule.comment')">
              <a-input
                :placeholder="$t('smartSchedule.comment')"
                v-decorator="[
                  'comment',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('smartSchedule.commentRequired'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <div class="form-actions mt-0">
              <a-button
                type="primary"
                htmlType="submit"
                :loading="addScheduleLoading"
                :disabled="addScheduleLoading"
                class="mr-3"
              >
                {{ $t("action.ajouter") }}
              </a-button>
              <a-button
                key="back"
                @click="handleCancel"
                :disabled="addScheduleLoading"
              >
                {{ $t("action.annuler") }}
              </a-button>
            </div>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('smartSchedule.cloneParam')">
          <a-form :form="form" @submit="cloneSchedule">
            <a-form-item :label="$t('smartSchedule.name')">
              <a-input
                :placeholder="$t('smartSchedule.name')"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.nom'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item :label="$t('smartSchedule.comment')">
              <a-input
                :placeholder="$t('smartSchedule.comment')"
                v-decorator="[
                  'comment',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('smartSchedule.commentRequired'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item :label="$t('smartSchedule.selectParamToClone')">
              <a-select
                style="width: 300px"
                :placeholder="$t('smartSchedule.selectSchedule')"
                v-decorator="[
                  'schedJobParam',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('smartSchedule.selectParamToClone'),
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="params in schedParamsList"
                  :key="params.key"
                  :value="params._id"
                  >{{ params.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <div class="form-actions mt-0">
              <a-button
                type="primary"
                htmlType="submit"
                :loading="addScheduleLoading"
                :disabled="addScheduleLoading"
                class="mr-3"
              >
                {{ $t("action.clone") }}
              </a-button>
              <a-button
                key="back"
                @click="handleCancel"
                :disabled="addScheduleLoading"
              >
                {{ $t("action.annuler") }}
              </a-button>
            </div>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
    <div>
      {{ $t("smartSchedule.selectSchedule") }} :
      <a-select
        style="width: 300px"
        :placeholder="$t('smartSchedule.selectSchedule')"
        v-model="selectedSchedule"
        @change="handleScheduleChange"
      >
        <a-select-option
          v-for="params in schedParamsList"
          :key="params.key"
          :value="params._id"
          >{{ params.name }}</a-select-option
        >
      </a-select>
      <a-button
        @click="showModal"
        class="ml-3"
        style="width: 40px"
        icon="plus"
      ></a-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
export default {
  name: "subjects",
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "SubjectForm" });
  },
  async created() {
    apiClient
      .post("/smartSchedule/schedJobParam/filter", { query: {} })
      .then(({ data }) => {
        this.schedParamsList = data;
        if (this.schedParamsList.length > 0)
          this.handleScheduleChange(this.schedParamsList[0]._id);
      })
      .catch((e) => {
        console.log(e);
        this.$message.error(this.$t("error.ajout"));
      });
  },
  props: {},
  data() {
    return {
      visibleModal: false,
      addScheduleLoading: false,
      form: null,
      schedParamsList: [],
      selectedSchedule: undefined,
    };
  },
  methods: {
    handleScheduleChange(value) {
      const record = this.schedParamsList.find((s) => {
        return s._id === value;
      });

      this.$emit("scheduleChange", value, record);
      this.selectedSchedule = value;
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addSchedule(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.addScheduleLoading = true;
          apiClient
            .put("/smartSchedule/schedJobParam", { data: values })
            .then((res) => {
              this.$message.success(
                this.$t("smartSchedule.scheduleParmsAdded")
              );
              res.data.key = res.data._id;
              this.schedParamsList.push(res.data);
              this.handleCancel();
              this.handleScheduleChange(res.data._id);
            })
            .catch((e) => this.$message.error(this.$t("error.ajout")))
            .finally(() => {
              this.addScheduleLoading = false;
            });
        }
      });
    },
    cloneSchedule(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.addScheduleLoading = true;
          const idToClone = values.schedJobParam;
          delete values.schedJobParam;
          apiClient
            .post("/smartSchedule/schedJobParam/clone/" + idToClone, values)
            .then((res) => {
              this.$message.success(
                this.$t("smartSchedule.scheduleParmsAdded")
              );
              res.data.key = res.data._id;
              this.schedParamsList.push(res.data);
              this.handleCancel();
              this.handleScheduleChange(res.data._id);
            })
            .catch((e) => this.$message.error(this.$t("error.ajout")))
            .finally(() => {
              this.addScheduleLoading = false;
            });
        }
      });
    },
  },
};
</script>
