<template>
  <div class="card col-xl-12 col-lg-12">
    <div class="card-header card-header-flex flex-column">
      <a-tabs
        class="kit-tabs"
        :default-active-key="activeKey"
        @change="callback"
      >
        <a-tab-pane :tab="this.$t('smartSchedule.generalParams')" key="1" />
        <a-tab-pane
          :tab="this.$t('smartSchedule.subjectsSchedParam')"
          key="2"
        />
        <a-tab-pane
          :tab="this.$t('smartSchedule.teacherClassParam')"
          key="3"
        ></a-tab-pane>
      </a-tabs>
    </div>
    <div class="card-body">
      <keep-alive>
        <component v-bind:is="currentTabComponent" :key="loadKey"></component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import generalParams from "./components/generalParams";
import subjectsSchedParam from "./components/subjectsSchedParam";
import teacherClassParam from "./components/teacherClassParam";

export default {
  name: "etablissement",
  components: {
    generalParams,
    subjectsSchedParam,
    teacherClassParam,
  },
  data() {
    return {
      activeKey: "1",
      loadKey: 0,
    };
  },
  computed: {
    currentTabComponent: function () {
      switch (this.activeKey) {
        case "1":
          return "generalParams";
        case "2":
          return "subjectsSchedParam";
        case "3":
          return "teacherClassParam";
      }
    },
  },
  methods: {
    callback: function (key) {
      this.activeKey = key;
      this.loadKey += 1;
    },
  },
};
</script>
