<template>
  <div>
    <div class="row pt-2">
      <SchedJobParam @scheduleChange="handleScheduleChange" />
    </div>
    <a-divider></a-divider>
    <a-col class="mt-5" v-if="loadingGenerealParams" :span="16" :offset="11">
      <a-spin size="large" />
    </a-col>
    <div v-if="selectedSchedule && !loadingGenerealParams">
      <div class="row">
        <div class="col-md-6">
          <a-select
            size="large"
            :default-value="defaultLevel[schoolType]"
            @change="handleLevelChange"
            class="pb-2 pt-2"
            style="width: 100%"
          >
            <a-select-option
              v-for="(value, name, index) in levelSelectData[schoolType]"
              :key="index"
              :value="name"
            >
              {{ value }}</a-select-option
            >
          </a-select>
        </div>
        <div class="col-md-6">
          <a-button
            type="primary"
            icon="book"
            class="mx-auto my-2"
            @click="showAddModal"
            style="width: 100%"
          >
            {{ $t("action.ajouter") }}
          </a-button>
        </div>
      </div>

      <div class="card">
        <!-- add module form -->
        <a-modal
          v-model="visibleAddModal"
          @cancel="handleCancel('add')"
          :title="$t('scolarite.ajouterMatiere')"
          :footer="false"
          width="50%"
        >
          <a-form :form="addForm" @submit="addSubject">
            <a-form-item :label="$t('emploi.matiere')">
              <multiselect
                v-model="selectedSubject"
                :placeholder="$t('emploi.choisirMatiere')"
                :options="[...modulesSpecific, ...modules, ...subjects]"
                :customLabel="
                  () =>
                    selectedSubject
                      ? selectedSubject.name
                      : $t('emploi.choisirMatiere')
                "
                @input="handleSubjectChange"
                :custom-label="$t('emploi.choisirMatiere')"
                :close-on-select="true"
                :preserve-search="true"
                :maxHeight="400"
                label="_id"
                track-by="_id"
                :preselect-first="false"
                :multiple="false"
                :allow-empty="false"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="align-text-top">{{ props.option.name }}</span>
                    <br />
                    <span
                      v-if="props.option.type == 'MP'"
                      class="option__small font-size-12 text-primary"
                    >
                      <b> module</b>
                    </span>
                    <span
                      v-else-if="props.option.type == 'MS'"
                      class="option__small font-size-12 text-primary"
                    >
                      <b> module specifique</b>
                    </span>
                    <span
                      v-else-if="
                        props.option.type == 'S' && props.option.special
                      "
                      class="option__small font-size-12 text-danger"
                    >
                      <b>Select matière supplémentaire </b>
                    </span>
                    <span
                      v-else-if="props.option.type == 'S'"
                      class="option__small font-size-12"
                    >
                      <b>Select matière </b>
                    </span>
                  </div>
                </template>
              </multiselect>
              <div
                class="ant-form-item-control has-error mt-2"
                v-if="selectedSubject == null && showSubjectError"
              >
                <div class="ant-form-explain">
                  {{ $t("emploi.choisirMatiere") }}
                </div>
              </div>
            </a-form-item>

            <div class="row">
              <div class="col-6">
                <a-form-item :label="$t('scolarite.nom')">
                  <a-input
                    :placeholder="$t('scolarite.nom')"
                    v-decorator="[
                      'name',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('requis.nom'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-6">
                <a-form-item :label="$t('smartSchedule.weeklyRate')">
                  <a-time-picker
                    :allowClear="false"
                    :minuteStep="15"
                    v-decorator="[
                      'weeklyRate',
                      {
                        initialValue: moment('04:00', 'HH:mm'),
                        rules: [
                          {
                            required: true,
                            message: $t('smartSchedule.weeklyRateRequired'),
                          },
                        ],
                      },
                    ]"
                    format="HH:mm"
                  />
                </a-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <a-form-item :label="$t('smartSchedule.maxDuration')">
                  <a-time-picker
                    :allowClear="false"
                    :minuteStep="15"
                    v-decorator="[
                      'maxDuration',
                      {
                        initialValue: moment('02:00', 'HH:mm'),
                        rules: [
                          {
                            required: true,
                            message: $t('smartSchedule.maxDuration'),
                          },
                        ],
                      },
                    ]"
                    format="HH:mm"
                  />
                </a-form-item>
              </div>
              <div class="col-6">
                <a-form-item :label="$t('smartSchedule.minDuration')">
                  <a-time-picker
                    :allowClear="false"
                    :minuteStep="15"
                    v-decorator="[
                      'minDuration',
                      {
                        initialValue: moment('01:00', 'HH:mm'),
                        rules: [
                          {
                            required: true,
                            message: $t('smartSchedule.minDuration'),
                          },
                        ],
                      },
                    ]"
                    format="HH:mm"
                  />
                </a-form-item>
              </div>
            </div>

            <div class="form-actions mt-0">
              <a-button
                type="primary"
                htmlType="submit"
                :loading="loadingClasse"
                class="mr-3"
              >
                {{ $t("action.ajouter") }}
              </a-button>
              <a-button
                key="back"
                @click="handleCancel('add')"
                :disabled="loadingClasse"
              >
                {{ $t("action.annuler") }}
              </a-button>
            </div>
          </a-form>
        </a-modal>

        <a-table
          :loading="tableLeading"
          :rowKey="'_id'"
          :columns="columns"
          :data-source="data"
          :pagination="true"
          :scroll="{ x: 'max-content' }"
        >
          <template slot="type" slot-scope="text">
            <a-tag color="blue">{{ $t("smartSchedule." + text) }}</a-tag>
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <span slot="operationSupprimer" slot-scope="text, record">
            <div class="editable-row-operations">
              <span>
                <a-button
                  :disabled="editingKey !== ''"
                  type="danger"
                  @click.stop
                >
                  <a-popconfirm
                    :title="$t('requis.supp')"
                    @confirm="() => supp(record.key)"
                  >
                    <a>{{ $t("action.supprimer") }}</a>
                  </a-popconfirm>
                </a-button>
              </span>
            </div>
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { Modal } from "ant-design-vue";
import { mapState } from "vuex";
import moment from "moment";
import SchedJobParam from "@/views/parametrage/smartSchedule/components/schedJodParam/index.vue";

export default {
  name: "subjectsSchedParam",
  computed: mapState(["settings", "user"]),
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "SubjectForm" });
  },
  components: {
    SchedJobParam,
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - subjects Schedule Param",
    });
  },
  data() {
    return {
      loadingGenerealParams: false,
      showSubjectError: false,
      moment,
      currentData: {},
      selectedSubject: null,
      schoolType: null,
      selectedSchedule: undefined,
      visibleAddScheduleModal: false,
      modules: [],
      subjects: [],
      modulesSpecific: [],
      cacheModules: [],
      cacheSubjects: [],
      cacheModulesSpecific: [],
      columns: [
        {
          title: this.$t("profil.nom"),
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            record.name.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.type"),
          dataIndex: "type",
          sorter: true,
          key: "type",
          sorter: (a, b) => a.type.length - b.type.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            customRender: "type",
          },
        },
        {
          title: this.$t("smartSchedule.weeklyRate"),
          dataIndex: "weeklyRate",
          sorter: true,
          key: "weeklyRate",
          sorter: (a, b) => a.weeklyRate.length - b.weeklyRate.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            customRender: "weeklyRate",
          },
        },

        {
          title: this.$t("smartSchedule.maxDuration"),
          dataIndex: "maxDuration",
          sorter: true,
          key: "maxDuration",
          sorter: (a, b) => a.maxDuration.length - b.maxDuration.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            customRender: "maxDuration",
          },
        },
        {
          title: this.$t("smartSchedule.minDuration"),
          dataIndex: "minDuration",
          sorter: true,
          key: "minDuration",
          sorter: (a, b) => a.minDuration.length - b.minDuration.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            customRender: "minDuration",
          },
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      tableLeading: true,
      visibleModal: false,
      visibleAddModal: false,
      form: this.$form.createForm(this),
      addScheduleForm: this.$form.createForm(this),
      addForm: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
      selectedModule: null,
      listSubjects: [],
      targetKeys: [],
      levelSelected: 1,
      targetAddKeys: [],
      defaultLevel: {
        ecole: "1",
        college: "7",
        lycee: "10",
        collegeEtLycee: "7",
        primaireEtCollege: "1",
      },
      levelSelectData: {
        jardinEnfant: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
        },
        ecole: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        },
        college: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
        lycee: {
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        collegeEtLycee: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        primaireEtCollege: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
      },
    };
  },
  methods: {
    async getSubjectSchedParam(level) {
      this.data = [];
      this.tableLeading = true;
      await apiClient
        .post("/smartSchedule/subjectsSchedParam/filter", {
          query: { level: level, schedJobParam: this.selectedSchedule },
        })
        .then(({ data }) => {
          if (data.length == 0) {
            this.$message.warning(this.$t("error.aucMatiere"));
            this.currentData = {};
            return;
          }

          this.currentData = data[0];

          this.data = this.currentData.subjects.map((s) => {
            const maxDuration = s.maxDuration || 120;
            const minDuration = s.minDuration || 60;
            return {
              key: s.subject._id,
              weeklyRate: moment({
                hours: s.weeklyRate / 60,
                minutes: s.weeklyRate % 60,
              }).format("HH:mm"),
              maxDuration: moment({
                hours: maxDuration / 60,
                minutes: maxDuration % 60,
              }).format("HH:mm"),
              minDuration: moment({
                hours: minDuration / 60,
                minutes: minDuration % 60,
              }).format("HH:mm"),
              name: s.name,
              type: "subject",
            };
          });
          this.data = this.data.concat(
            this.currentData.modules.map((s) => {
              const maxDuration = s.maxDuration || 120;
              const minDuration = s.minDuration || 60;
              return {
                key: s.module._id,
                weeklyRate: moment({
                  hours: s.weeklyRate / 60,
                  minutes: s.weeklyRate % 60,
                }).format("HH:mm"),
                maxDuration: moment({
                  hours: maxDuration / 60,
                  minutes: maxDuration % 60,
                }).format("HH:mm"),
                minDuration: moment({
                  hours: minDuration / 60,
                  minutes: minDuration % 60,
                }).format("HH:mm"),
                name: s.name,
                type: "module",
              };
            })
          );
          this.data = this.data.concat(
            this.currentData.modulesSpecific.map((s) => {
              const maxDuration = s.maxDuration || 120;
              const minDuration = s.minDuration || 60;
              return {
                key: s.moduleSpecific._id,
                weeklyRate: moment({
                  hours: s.weeklyRate / 60,
                  minutes: s.weeklyRate % 60,
                }).format("HH:mm"),
                maxDuration: moment({
                  hours: maxDuration / 60,
                  minutes: maxDuration % 60,
                }).format("HH:mm"),
                minDuration: moment({
                  hours: minDuration / 60,
                  minutes: minDuration % 60,
                }).format("HH:mm"),
                name: s.name,
                type: "moduleSpecific",
              };
            })
          );
          this.tableLeading = false;
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
          this.tableLeading = false;
        });
      this.tableLeading = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addSubject(e) {
      e.preventDefault();
      this.addForm.validateFields(async (err, values) => {
        this.showSubjectError = !this.selectedSubject;
        if (!err && this.selectedSubject) {
          this.loadingClasse = true;

          const subjectId = this.selectedSubject._id;

          const weeklyRate =
            values.weeklyRate.hours() * 60 + values.weeklyRate.minutes();
          const minDuration =
            values.minDuration.hours() * 60 + values.minDuration.minutes();
          const maxDuration =
            values.maxDuration.hours() * 60 + values.maxDuration.minutes();

          if (weeklyRate < maxDuration) {
            this.loadingClasse = false;
            return this.$message.error(
              this.$t("smartSchedule.weeklyRateMaxError")
            );
          }

          if (weeklyRate < minDuration) {
            this.loadingClasse = false;
            return this.$message.error(
              this.$t("smartSchedule.weeklyRateMinError")
            );
          }

          const subjects = [];
          const modules = [];
          const modulesSpecific = [];

          if (this.selectedSubject.type == "S")
            subjects.push({
              name: values.name,
              weeklyRate,
              minDuration,
              maxDuration,
              subject: this.selectedSubject._id,
            });
          else if (this.selectedSubject.type == "MP")
            modules.push({
              name: values.name,
              weeklyRate,
              minDuration,
              maxDuration,
              module: this.selectedSubject._id,
            });
          else if (this.selectedSubject.type == "MS")
            modulesSpecific.push({
              name: values.name,
              weeklyRate,
              minDuration,
              maxDuration,
              moduleSpecific: this.selectedSubject._id,
            });

          if (this.currentData._id) {
            subjects.push(
              ...this.currentData.subjects.map((s) => ({
                name: s.name,
                weeklyRate: s.weeklyRate,
                minDuration: s.minDuration || 60,
                maxDuration: s.maxDuration || 120,
                subject: s.subject._id,
              }))
            );
            modules.push(
              ...this.currentData.modules.map((s) => ({
                name: s.name,
                weeklyRate: s.weeklyRate,
                minDuration: s.minDuration || 60,
                maxDuration: s.maxDuration || 120,
                module: s.module._id,
              }))
            );
            modulesSpecific.push(
              ...this.currentData.modulesSpecific.map((s) => ({
                name: s.name,
                weeklyRate: s.weeklyRate,
                minDuration: s.minDuration || 60,
                maxDuration: s.maxDuration || 120,
                moduleSpecific: s.moduleSpecific._id,
              }))
            );
          }

          if (!this.currentData._id) {
            await apiClient
              .put("/smartSchedule/subjectsSchedParam", {
                data: {
                  schedJobParam: this.selectedSchedule,
                  level: this.levelSelected,
                  subjects,
                  modules,
                  modulesSpecific,
                },
              })
              .then(({ data }) => {
                this.currentData = data;
                this.$message.success(
                  this.$t("success.modificationsMatieresEffectue")
                );

                this.handleCancel();
              })
              .catch((e) =>
                this.$message.error(this.$t("error.impoModifiMatieres"))
              )
              .finally(() => {
                this.loadingClasse = false;
                this.visibleAddModal = false;
              });
          } else {
            await apiClient
              .patch(
                "/smartSchedule/subjectsSchedParam/" + this.currentData._id,
                {
                  data: {
                    subjects,
                    modules,
                    modulesSpecific,
                  },
                }
              )
              .then((res) => {
                this.$message.success(
                  this.$t("success.modificationsMatieresEffectue")
                );

                this.handleCancel();
              })
              .catch((e) =>
                this.$message.error(this.$t("error.impoModifiMatieres"))
              )
              .finally(() => {
                this.loadingClasse = false;
                this.visibleAddModal = false;
              });
          }
          this.getSubjectSchedParam(this.levelSelected);

          this.subjects = this.subjects.filter((s) => s._id != subjectId);
          this.modules = this.modules.filter((s) => s._id != subjectId);
          this.modulesSpecific = this.modulesSpecific.filter(
            (s) => s._id != subjectId
          );
        }
      });
    },

    renderItem(item) {
      const customLabel = <span class="custom-item">{item.name}</span>;

      return {
        label: customLabel, // for displayed item
        value: item.name, // for title and filter matching
      };
    },
    handleChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
    handleAddChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetAddKeys = targetKeys;
      console.log(this.targetAddKeys);
    },
    showModal() {
      this.visibleModal = true;
    },
    showAddModal() {
      this.visibleAddModal = true;
      this.showSubjectError = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleCancel(type = "edit") {
      console.log(type);
      if (type == "edit") {
        this.visibleModal = false;
        this.form.resetFields();
      } else {
        this.addForm.resetFields();
        this.visibleAddModal = false;
        this.targetAddKeys = [];
      }
      this.selectedSubject = null;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },

    async handleLevelChange(value) {
      this.levelSelected = value;

      this.modules = [];
      this.modulesSpecific = [];
      this.subjects = [];

      this.getSubjectSchedParam(value);

      await apiClient
        .post("/module/filter", {
          query: { status: "active", level: Number(this.levelSelected) },
        })
        .then((res) => {
          this.modules = res.data
            .filter((m) => m.status == "active")
            .map((m) => {
              m.type = "MP";
              return m;
            });

          this.modules.map((m) => {
            this.subjects.push(
              ...m.subjects
                .filter((s) => s.status == "active")
                .map((s) => {
                  s.type = "S";
                  return s;
                })
            );
          });
        });

      await apiClient
        .post("/moduleSpecific/filter", {
          query: { status: "active", level: Number(this.levelSelected) },
        })
        .then((res) => {
          this.modulesSpecific = res.data
            .filter((m) => m.status == "active")
            .map((m) => {
              m.type = "MS";
              return m;
            });
          this.modules.map((m) => {
            this.subjects.push(
              ...m.subjects
                .filter(
                  (s) =>
                    s.status == "active" &&
                    !this.subjects.find((ss) => s._id == ss._id)
                )
                .map((s) => {
                  s.type = "S";
                  return s;
                })
            );
          });
        });

      await apiClient
        .post("/subject/filter", {
          query: { status: "active" },
        })
        .then((res) => {
          res.data
            .filter((m) => !this.subjects.find((s) => s._id == m._id))
            .map((m) => {
              m.type = "S";
              m.special = true;
              this.subjects.push(m);
            });
        });

      this.cacheModules = [...this.modules];
      this.cacheModulesSpecific = [...this.modulesSpecific];
      this.cacheSubjects = [...this.subjects];

      if (this.currentData._id) {
        this.subjects = this.subjects.filter(
          (s) =>
            !this.currentData.subjects.find((ss) => ss.subject._id == s._id)
        );
        this.modules = this.modules.filter(
          (s) => !this.currentData.modules.find((ss) => ss.module._id == s._id)
        );
        this.modulesSpecific = this.modulesSpecific.filter(
          (s) =>
            !this.currentData.modulesSpecific.find(
              (ss) => ss.moduleSpecific._id == s._id
            )
        );
      }

      console.log(`selected ${this.levelSelected}`);
    },

    confirmDelete(key) {
      apiClient
        .delete("/module/" + key)
        .then(() => {
          this.$message.success(this.$t("success.moduleSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.moduleNonSupp")))
        .finally(() => (this.visibleModal = false));
    },

    supp(id) {
      this.currentData.subjects = this.currentData.subjects.filter(
        (s) => s.subject._id != id
      );
      this.currentData.modules = this.currentData.modules.filter(
        (s) => s.module._id != id
      );
      this.currentData.modulesSpecific =
        this.currentData.modulesSpecific.filter(
          (s) => s.moduleSpecific._id != id
        );
      apiClient
        .patch("/smartSchedule/subjectsSchedParam/" + this.currentData._id, {
          data: {
            subjects: this.currentData.subjects,
            modules: this.currentData.modules,
            modulesSpecific: this.currentData.modulesSpecific,
          },
        })
        .then(() => {
          this.$message.success(this.$t("success.matiereSupp"));

          const subject = this.cacheSubjects.find((s) => s._id == id);
          if (subject) this.subjects.push(subject);
          const module = this.cacheModules.find((s) => s._id == id);
          if (module) this.modules.push(module);
          const moduleSpecific = this.cacheModulesSpecific.find(
            (s) => s._id == id
          );
          if (moduleSpecific) this.modulesSpecific.push(moduleSpecific);

          this.getSubjectSchedParam(this.levelSelected);
        })
        .catch(() => this.$message.error(this.$t("error.erreur")));
    },
    async handleScheduleChange(val) {
      this.selectedSchedule = val;
      this.loadingGenerealParams = true;

      this.tableLeading = true;

      this.schoolType = this.user.building.find(
        (el) => el.dbName == this.settings.activeBuilding
      ).type;

      const defaultLevel = this.defaultLevel[this.schoolType];

      await this.handleLevelChange(defaultLevel);

      this.loadingGenerealParams = false;
    },
    handleSubjectChange() {
      if (this.selectedSubject) {
        this.addForm.setFieldsValue({ name: this.selectedSubject.name });
        if (["MS", "MP"].includes(this.selectedSubject.type)) {
          this.includedSubjects = this.selectedSubject.subjects.map(
            (s) => s._id
          );
        }
      }
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.arabicStyle {
  direction: rtl;
  font-size: 20px;
}
.card >>> .ant-table-tbody tr {
  cursor: pointer !important;
}
</style>
>
